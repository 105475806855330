import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import styled from 'styled-components';
import emailMisspelled, { top100 } from 'email-misspelled';

import { mobile } from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import { Text } from 'components';

interface EmailFormProps {
  loading: boolean;
  error: string;
  placeholder: string;
  continueBtnTitle: string;
  onSubmit: (email: string) => void;
  showEmailSuggestion?: boolean;
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  @media ${mobile} {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 26rem;

  @media ${mobile} {
    min-width: 100%;
  }
`;

const Submit = styled(PrimaryButton)`
  min-width: 15rem;
`;

const InputStyled = styled.input`
  border: 0.06rem solid ${({ theme }) => theme.colors.light100};
  background-color: ${({ theme }) => theme.colors.light40};
  border-radius: 0.5rem;
  outline: none;
  font-family: Inter;
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.dark80};
  height: 3.63rem;
  padding: 0 1.5rem;

  ::placeholder {
    color: ${({ theme }) => theme.colors.dark60};
  }

  @media ${mobile} {
    padding: 0 1rem;
  }
`;

const Error = styled.div`
  font-family: Inter;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  height: 1rem;
  color: red;
  margin: 0.5rem 0;
`;

const SuggestionDropdownContainer = styled.div`
  border-radius: 0.5rem;
  background: rgba(224, 224, 224, 0.26);
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const Label = styled(Text)`
  color: #555770;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.021rem;
`;

const SuggestedEmail = styled(Text)`
  color: #555770;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.225rem */
  letter-spacing: -0.021rem;
  cursor: pointer;
`;

const EmailForm: FC<EmailFormProps> = ({
  loading,
  error,
  placeholder = 'Please enter your email',
  continueBtnTitle = 'Continue',
  onSubmit,
  showEmailSuggestion = false,
  ...props
}) => {
  const [email, setEmail] = useState<string>('');
  const emailChecker = emailMisspelled({ domains: top100 });
  const suggestions = emailChecker(email);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit(email);
  };

  const suggestionIsVisible = showEmailSuggestion && !!suggestions?.length;

  return (
    <Container onSubmit={handleSubmit} {...props}>
      <ContentContainer>
        <InputStyled
          name="email"
          type="email"
          placeholder={placeholder}
          disabled={loading}
          required
          onChange={handleChange}
          value={email}
        />
        {suggestionIsVisible ? (
          <SuggestionDropdownContainer id="suggestions">
            <Label>Did you mean?</Label>
            {suggestions.map((option, index) => (
              <SuggestedEmail
                key={index}
                value={option.corrected}
                onClick={() => setEmail(option.corrected)}
              >
                {option.corrected}
              </SuggestedEmail>
            ))}
          </SuggestionDropdownContainer>
        ) : null}
        <Error>{error}</Error>
      </ContentContainer>
      <Submit
        disabled={loading}
        loading={loading}
        type="submit"
        cornerVariant="round"
      >
        {continueBtnTitle}
      </Submit>
    </Container>
  );
};

export default EmailForm;
